import Vue from 'vue'
import Vuex from 'vuex'
import TrainerStore from './trainer'
import ComponentStore from './component'
import InstructionPopupStore from './instruction-image-popup'
import PropResetPopup from './prop-reset-popup'
import PromptPopupStore from './prompt-popup'
import TTInstanceService from '../services/TTInstanceService'
import LocalizationStore from './localization'
import PermissionsStore from './permissions'
import AliasesStore from './aliases'
import FeatureFlags from './feature-flags'
import BodyFatResetPopup from './bodyfat-reset-popup'

Vue.use(Vuex)
// Vue.config.debug = true

export default new Vuex.Store({
  // TODO: refactor store namings
  modules: {
    trainers: TrainerStore,
    components: ComponentStore,
    instructionPopup: InstructionPopupStore,
    propResetPopup: PropResetPopup,
    promptPopup: PromptPopupStore,
    localization: LocalizationStore,
    permissions: PermissionsStore,
    aliases: AliasesStore,
    featureFlags: FeatureFlags,
    bodyFatResetPopup: BodyFatResetPopup
  },
  state: {
    user: {},
    trainer: '',
    companyUrl: '',
    template: {id: ''},
    instance: '',
    component: '',
    themeActive: false,
    privateMode: false,
    previewRefreshRequired: false,
    previewCssInjectRequired: false,
    componentPropRefreshRequired: false,
    accessToken: '',
    cmsToken: '',
    loading: 0
  },
  mutations: {
    setLoading (state, payload) {
      if (payload) {
        state.loading++
      } else if (state.loading !== 0) {
        state.loading--
      }
    },
    setTemplate (state, payload) {
      state.template = payload
    },
    setTrainer (state, payload) {
      state.trainer = payload
    },
    setInstance (state, payload) {
      state.instance = payload
    },
    setUser (state, payload) {
      state.user = payload
    },
    setCompanyUrl (state, payload) {
      state.companyUrl = payload
    },
    setComponent (state, payload) {
      state.component = payload
    },
    setThemeActive (state, payload) {
      state.themeActive = payload
    },
    setPrivateMode (state, payload) {
      state.privateMode = payload
    },
    setPreviewRefreshRequired (state, payload) {
      state.previewRefreshRequired = payload
    },
    setPreviewCssInjectRequired (state, payload) {
      state.previewCssInjectRequired = payload
    },
    setComponentPropRefreshRequired (state, payload) {
      state.componentPropRefreshRequired = payload
    },
    setAccessToken (state, payload) {
      state.accessToken = payload
    },
    setCmsToken (state, payload) {
      state.cmsToken = payload
    },
    removeAuth (state) {
      state.user = {}
      state.accessToken = ''
    }
  },
  actions: {
    loadInstance ({commit, state}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        let instanceService = new TTInstanceService()
        instanceService.getInstance(state.template.id, this.state.trainer)
          .then(response => {
            commit('setInstance', response.data.instance)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            commit('setLoading', false)
          })
      })
    }
  },
  getters: {
    checkAuth () {
      if (localStorage.getItem('user')) {
        return true
      } else {
        return false
      }
    },
    getUser (state) {
      return state.user
    },
    getTrainer (state) {
      return state.trainer
    },
    getCompanyUrl (state) {
      return state.companyUrl
    },
    getTemplate (state) {
      return state.template
    },
    getInstance (state) {
      return state.instance
    },
    getAccessToken (state) {
      return state.accessToken
    },
    getCmsToken (state) {
      return state.cmsToken
    },
    getLoading (state) {
      return state.loading
    },
    getComponent (state) {
      return state.component
    },
    getThemeActive (state) {
      return state.themeActive
    },
    getPrivateMode (state) {
      return state.privateMode
    },
    getPreviewRefreshRequired (state) {
      return state.previewRefreshRequired
    },
    getPreviewCssInjectRequired (state) {
      return state.previewCssInjectRequired
    },
    getComponentPropRefreshRequired (state) {
      return state.componentPropRefreshRequired
    }
  }
})
